import React from "react"
import { Layout } from "../components/Layout/Layout"
import { graphql } from "gatsby"
import { PageTitle } from "../components/PageTitle/PageTitle"
import { Eversports } from "../components/Eversports/Eversports"

interface WorkshopProps {
  data: {
    cms: {
      page: {
        title: string
        fields: {
          content: string
        }
      }
    }
  }
}

export const Workshops: React.FC<WorkshopProps> = ({ data }) => {
  return (
    <Layout withPadding>
      <div className="flex w-full flex-grow flex-col items-center">
        <PageTitle marginBottom="mb-28">{data.cms.page.title}</PageTitle>
        <Eversports
          title="Workshops"
          widgetCode="t4ez9i?list=workshop"
          height={1000}
          className="w-11/12 bg-white px-8 py-6"
        />
      </div>
    </Layout>
  )
}

export default Workshops
export const query = graphql`
  {
    cms {
      page(id: "/workshops", idType: URI) {
        fields {
          content
        }
        title
      }
    }
  }
`
